import React, { useMemo } from "react";

import "./UserList.scss";

export interface UserListProps {
  users: Map<string, string>;
  currentUserTimes: { [key: string]: number };
  waitingUsers: { [key: string]: boolean };
}

export const formatTime = (d: number) => {
  const h = Math.floor(d / 3600)
    .toString()
    .padStart(2, "0");
  const m = Math.floor((d % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const s = Math.floor((d % 3600) % 60)
    .toString()
    .padStart(2, "0");

  return `${h}:${m}:${s}`;
};

export const UserList: React.FunctionComponent<UserListProps> = ({
  users,
  waitingUsers,
  currentUserTimes,
}) => {
  const userArr = useMemo(() => Array.from(users.entries()), [users]);

  return (
    <div className="userlist">
      <h2>Users</h2>
      {userArr.map(([id, name]) => (
        <div key={id}>
          {name} - {formatTime(currentUserTimes[id] || 0)} -{" "}
          {waitingUsers[id] ? "Ready" : "Loading"}
        </div>
      ))}
    </div>
  );
};
