import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.scss";
import { Home } from "./pages/Home";
import { Room } from "./pages/Room";

export const App: React.FunctionComponent = () => {
  return (
    <div>
      <Router>
        <Route path="/room/:roomName" component={Room} />
        <Route path="/" exact component={Home} />
      </Router>
    </div>
  );
};
