import React, { useCallback, useEffect, useRef, useState } from "react";
import vjs, { VideoJsPlayer } from "video.js";

import "video.js/dist/video-js.css";
import "@videojs/themes/dist/fantasy/index.css";
import "./Player.scss";
import { LibraryItem } from "../pages/Room";

export type ControllerAction =
  | { type: "play" }
  | { type: "pause" }
  | { type: "seek"; time: number };
// | {
//     type: "setvideo";
//     info: LibraryItem;
//   };

export interface VideoPlayerProps {
  onPlay?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onPause?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onWaiting?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onStalled?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onPlaying?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onCanPlay?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onSeeking?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onSeeked?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  onTimeUpdate?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
  controllerFn?: React.MutableRefObject<
    ((action: ControllerAction) => void) | null
  >;
  videoInfo?: LibraryItem | null;
}

export const VideoPlayer: React.FunctionComponent<VideoPlayerProps> = ({
  controllerFn,
  videoInfo,
  onPlay,
  onPause,
  onSeeking,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const [synthEvent, setSynthEvent] = useState<
    "play" | "pause" | "seek" | null
  >(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    const player = vjs(videoRef.current, {
      // sources: [
      //   // {
      //   //   src: "http://localhost:8080/mbmbam1/playlist.m3u8",
      //   //   type: "application/x-mpegURL",
      //   // },
      //   {
      //     src: "http://localhost:8080/railgun1/1080p.m3u8",
      //     type: "application/x-mpegURL",
      //   },
      // ],
      // tracks: [
      //   {
      //     src: "http://localhost:8080/railgun1/subtitles.vtt",
      //     kind: "subtitles",
      //     // mode: "showing",
      //     srclang: "eng",
      //   },
      // ],
      controls: true,
      userActions: {
        hotkeys: true,
      },
      // fluid: true,
    });
    setPlayer(player);
  }, [videoRef]);

  useEffect(() => {
    if (!player || !wrapperRef.current) {
      return;
    }

    const cb = () => {
      if (!player || !wrapperRef.current) {
        return;
      }
      player.height(wrapperRef.current.clientHeight);
      player.width(wrapperRef.current.clientWidth);
    };
    cb();

    window.addEventListener("resize", cb);

    return () => window.removeEventListener("resize", cb);
  }, [player]);

  useEffect(() => {
    if (!controllerFn || !videoRef) {
      return;
    }

    controllerFn.current = (action) => {
      // const player = videoRef.current;
      if (!player) {
        return;
      }
      switch (action.type) {
        case "play":
          player.play();
          break;
        case "pause":
          player.pause();
          break;
        case "seek":
          player.currentTime(action.time);
          break;
      }
      setSynthEvent(action.type);
    };
  }, [player, controllerFn]);

  useEffect(() => {
    if (!videoInfo || !player) {
      return;
    }
    player.src(videoInfo.sources);
    videoInfo.subtitles.forEach((i) => player.addRemoteTextTrack(i, false));
  }, [videoInfo, player]);

  const onPlayHandler = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      if (synthEvent === "play") {
        setSynthEvent(null);
        return;
      }
      setSynthEvent(null);
      onPlay?.(event);
    },
    [onPlay, synthEvent],
  );
  const onPauseHandler = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      if (synthEvent === "pause") {
        setSynthEvent(null);
        return;
      }
      setSynthEvent(null);
      onPause?.(event);
    },
    [onPause, synthEvent],
  );
  const onSeekingHandler = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
      if (synthEvent === "seek") {
        setSynthEvent(null);
        return;
      }
      setSynthEvent(null);
      onSeeking?.(event);
    },
    [onSeeking, synthEvent],
  );

  return (
    <>
      <div className="video-wrapper" ref={wrapperRef}>
        <div data-vjs-player>
          <video
            className="video-js vjs-theme-fantasy"
            ref={videoRef}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            onSeeking={onSeekingHandler}
            {...props}
          />
        </div>
      </div>
    </>
  );
};
