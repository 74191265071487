import React from "react";
import { LibraryItem } from "../pages/Room";

import "./Library.scss";

export interface LibraryProps {
  library: LibraryItem[];
  onSelect?: (item: LibraryItem) => void;
}

export const Library: React.FunctionComponent<LibraryProps> = ({
  library,
  onSelect,
}) => {
  return (
    <div className="library">
      <h2>Library</h2>
      {library
        .sort((a, b) => {
          if (a.name === b.name) return 0;
          if (a.name > b.name) return 1;
          return -1;
        })
        .map((item) => (
          <div key={item.name} onClick={() => onSelect?.(item)}>
            {item.name}
          </div>
        ))}
    </div>
  );
};
