import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

export const Home: React.FunctionComponent = () => {
  const history = useHistory();
  const [nickname, setNickname] = useState<string | null>(null);
  const [roomName, setRoomName] = useState<string>("");

  useEffect(() => {
    if (nickname === null) {
      setNickname(localStorage.getItem("name"));
    } else {
      localStorage.setItem("name", nickname);
    }
  }, [nickname]);

  const joinRoom = useCallback(
    (ev: React.FormEvent) => {
      ev.preventDefault();
      history.push(`/room/${roomName}`);
    },
    [roomName, history],
  );

  return (
    <div>
      <form onSubmit={joinRoom}>
        <input
          type="text"
          name="nickname"
          placeholder="nickname"
          onChange={(ev) => setNickname(ev.target.value)}
          value={nickname || ""}
        />

        <input
          type="text"
          name="roomname"
          placeholder="roomname"
          onChange={(ev) => setRoomName(ev.target.value)}
          value={roomName}
        />

        <button type="submit">Join</button>
      </form>
    </div>
  );
};
