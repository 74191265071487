import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { io, Socket } from "socket.io-client";
import { ControllerAction, VideoPlayer } from "../components/Player";
import vjs from "video.js";
import { Library } from "../components/Library";
import { UserList } from "../components/UserList";

import "./Room.scss";

const TIME_DIFFERENCE = 0.3;

export interface LibraryItem {
  name: string;
  sources: vjs.Tech.SourceObject[];
  subtitles: vjs.TextTrackOptions[];
}

type VideoStatus =
  | "none"
  | "playing"
  | "paused"
  | "stopped"
  | "buffering"
  | "seeking"
  | "waiting"
  | "stalled";

export interface VideoState {
  currentVideo: LibraryItem | null;
  currentTime: number | null;
  videoStatus: VideoStatus;
}

interface RoomState {
  name: string;
  title: string;
  videoState: VideoState;
  users: Map<string, string>;
  library: LibraryItem[];
}

//@ts-ignore
interface RoomDetails extends RoomState {
  users: { [key: string]: string };
}

type RoomAction =
  | { type: "user:name"; value: { name: string; user: string } }
  | { type: "user:join"; value: { name: string; user: string } }
  | { type: "user:leave"; value: { user: string } }
  | { type: "room:details"; value: RoomDetails }
  | { type: "video:set"; value: { user: string; item: LibraryItem } }
  | { type: "setVideoStatus"; value: VideoStatus }
  | { type: "setCurrentTime"; value: number };

const roomReducer = (state: RoomState, action: RoomAction): RoomState => {
  switch (action.type) {
    case "room:details":
      return {
        ...action.value,
        users: new Map(Object.entries(action.value.users)),
      };
    case "user:join":
    case "user:name":
      const usersName = new Map(state.users.entries());
      usersName.set(action.value.user, action.value.name);
      return {
        ...state,
        users: usersName,
      };
    case "user:leave":
      const usersLeave = new Map(state.users.entries());
      usersLeave.delete(action.value.user);
      return {
        ...state,
        users: usersLeave,
      };
    case "video:set":
      return {
        ...state,
        videoState: { ...state.videoState, currentVideo: action.value.item },
      };
    case "setVideoStatus":
      return {
        ...state,
        videoState: { ...state.videoState, videoStatus: action.value },
      };
    case "setCurrentTime":
      return {
        ...state,
        videoState: { ...state.videoState, currentTime: action.value },
      };
  }
  //@ts-ignore
  // console.log("Missing reduce:", action.type, action.value);
  return state;
};

export const Room: React.FunctionComponent = () => {
  const { roomName } = useParams<{ roomName: string }>();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [state, reducer] = useReducer(roomReducer, {
    name: roomName,
    title: roomName,
    videoState: {
      currentVideo: null,
      currentTime: null,
      videoStatus: "none",
    },
    users: new Map<string, string>(),
    library: [],
  });
  const controllerRef = useRef<((action: ControllerAction) => void) | null>(
    null,
  );
  const [waitingUsers, setWaitingUsers] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [currentUserTimes, setCurrentUserTimes] = useState<{
    [key: string]: number;
  }>({});
  const [firstPlay, setFirstPlay] = useState<boolean>(true);

  useEffect(() => {
    const sock = io(
      process.env.REACT_APP_SOCKET_URL || "http://localhost:3000",
      {
        transports: ["websocket"],
        query: { name: localStorage.getItem("name") || "" },
      },
    );
    setSocket(sock);
    return () => {
      sock.disconnect();
      setSocket(null);
    };
  }, [roomName]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket.on("connect", () => {
      socket.emit("room:join", roomName);
    });
    socket.onAny((...args) => {
      reducer({ type: args[0], value: args[1] });
    });

    return () => {
      socket.off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, reducer]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    const status = state.videoState.videoStatus;
    const currentTime = state.videoState.currentTime || 0;

    socket.on("video:play", ({ time }: { time: number }) => {
      if (status === "playing" || !controllerRef.current) {
        return;
      }
      controllerRef.current({ type: "play" });
      const diff = Math.abs(currentTime - time);
      if (diff > TIME_DIFFERENCE) {
        controllerRef.current({ type: "seek", time });
      }
    });
    socket.on("video:pause", ({ time }: { time: number }) => {
      if (status === "paused" || !controllerRef.current) {
        return;
      }
      controllerRef.current({ type: "pause" });
      const diff = Math.abs(currentTime - time);
      if (diff > TIME_DIFFERENCE) {
        controllerRef.current({ type: "seek", time });
      }
    });
    socket.on(
      "video:seek",
      ({ time, user }: { time: number; user: string }) => {
        if (status === "seeking" || !controllerRef.current) {
          return;
        }
        // controllerRef.current({ type: "pause" });
        const diff = Math.abs(currentTime - time);
        if (diff > TIME_DIFFERENCE) {
          controllerRef.current({ type: "seek", time });
          reducer({ type: "setVideoStatus", value: "seeking" });
        }
        setWaitingUsers({});
        setCurrentUserTimes((currentUserTimes) => ({
          ...currentUserTimes,
          [user]: time,
        }));
      },
    );
    socket.on("video:canPlay", ({ user }: { time: number; user: string }) => {
      console.log("canplay");
      setWaitingUsers({ ...waitingUsers, [user]: true });

      // if (!controllerRef.current) {
      //   return;
      // }
      // let start = Array.from(state.users.keys()).every(
      //   (user) => !!waitingUsers[user],
      // );
      // if (start) {
      //   reducer({ type: "setVideoStatus", value: "playing" });
      //   controllerRef.current({ type: "play" });
      //   setWaitingUsers({});
      // }
    });
    socket.on(
      "video:currentTime",
      ({ time, user }: { time: number; user: string }) => {
        setCurrentUserTimes((currentUserTimes) => ({
          ...currentUserTimes,
          [user]: time,
        }));
      },
    );
    socket.on("video:set", () => {
      setFirstPlay(true);
      setCurrentUserTimes({});
      reducer({ type: "setCurrentTime", value: 0 });
    });

    return () => {
      socket.off("video:play");
      socket.off("video:pause");
      socket.off("video:seek");
      socket.off("video:canPlay");
      socket.off("video:currentTime");
      socket.off("video:set");
    };
  }, [socket, state.videoState, waitingUsers, state.users]);

  const eventPrinter = useCallback(
    (event: any) => {
      console.log(event, state.videoState);
    },
    [state.videoState],
  );

  const playEvent = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      if (state.videoState.videoStatus === "playing" || !socket) {
        return;
      }
      reducer({ type: "setVideoStatus", value: "playing" });
      if (firstPlay) {
        console.log(
          "AAAAAA",
          Object.values(currentUserTimes).sort().reverse()[0],
          state.videoState.currentTime,
        );
        controllerRef.current?.({
          type: "seek",
          time:
            Object.values(currentUserTimes).sort().reverse()[0] ||
            state.videoState.currentTime ||
            0,
        });
      }
      socket.emit("video:play", event.currentTarget.currentTime);
    },
    [state.videoState, socket, firstPlay, currentUserTimes],
  );

  const pauseEvent = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      if (["paused"].includes(state.videoState.videoStatus) || !socket) {
        return;
      }
      reducer({ type: "setVideoStatus", value: "paused" });
      socket.emit("video:pause", event.currentTarget.currentTime);
    },
    [state.videoState, socket],
  );

  const timeUpdateEvent = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      if (!socket) {
        return;
      }
      const currentTime = event.currentTarget.currentTime;
      reducer({ type: "setCurrentTime", value: currentTime });
      socket.emit("video:currentTime", currentTime);
    },
    [socket],
  );

  const seekingEvent = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      if (firstPlay) {
        setFirstPlay(false);
        return;
      }
      const diff = Math.abs(
        (state.videoState.currentTime || 0) - event.currentTarget.currentTime,
      );
      console.log("seek event", diff, state.videoState.videoStatus);
      if (
        state.videoState.videoStatus === "seeking" ||
        !socket ||
        diff < TIME_DIFFERENCE
      ) {
        return;
      }
      reducer({ type: "setVideoStatus", value: "seeking" });
      socket.emit("video:seek", event.currentTarget.currentTime);
    },
    [state.videoState, socket, firstPlay],
  );

  const seekedEvent = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      if (state.videoState.videoStatus === "waiting" || !socket) {
        return;
      }
      reducer({ type: "setVideoStatus", value: "waiting" });
      socket.emit("video:waiting", event.currentTarget.currentTime);
    },
    [state.videoState, socket],
  );

  const canPlayEvent = useCallback(
    (event: React.SyntheticEvent<HTMLVideoElement>) => {
      if (
        !["waiting", "seeking"].includes(state.videoState.videoStatus) ||
        !socket
      ) {
        return;
      }
      reducer({ type: "setVideoStatus", value: "waiting" });
      socket.emit("video:canPlay", event.currentTarget.currentTime);
    },
    [state.videoState, socket],
  );

  const setVideoEvent = useCallback(
    (item: LibraryItem) => {
      socket?.emit("video:set", item);
    },
    [socket],
  );

  return (
    <div className="room">
      <VideoPlayer
        onPlay={playEvent}
        onPause={pauseEvent}
        onWaiting={eventPrinter}
        onStalled={eventPrinter}
        onPlaying={eventPrinter}
        onCanPlay={canPlayEvent}
        onSeeking={seekingEvent}
        onSeeked={seekedEvent}
        onTimeUpdate={timeUpdateEvent}
        controllerFn={controllerRef}
        videoInfo={state.videoState.currentVideo}
      />
      {/* <ul>
        {users.map(([userId, nickname]) => (
          <li key={userId}>
            {nickname} ({Math.round(currentUserTimes[userId] || 0)}) (
            {!!waitingUsers[userId] ? "true" : "false"})
          </li>
        ))}
      </ul> */}
      <div className="info">
        <UserList
          users={state?.users}
          waitingUsers={waitingUsers}
          currentUserTimes={currentUserTimes}
        />
        <Library library={state.library} onSelect={setVideoEvent} />
      </div>
    </div>
  );
};
